<script setup>
const props =defineProps({
    value: String,
    for: String,
});

const forValue = props.for;
</script>

<template>
    <label v-if="forValue" :for="forValue" class="block font-medium text-sm text-gray-700">
        <span v-if="value">{{ value }}</span>
        <span v-else><slot /></span>
    </label>
    <span v-else class="block font-medium text-sm text-gray-700">
        <span v-if="value">{{ value }}</span>
        <span v-else><slot /></span>
    </span>
</template>
